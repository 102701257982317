import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router";


function InputSample() {
    
    const location = useLocation();
    const navigate = useNavigate();
    if(!location.state) navigate('/');

//console.log(location.state.profile.id);
//console.log(location.state.profile.properties.nickname);
//console.log(location.state.profile.properties.thumbnail_image);
  
  
  
  const [nameError, setNameError] = useState(false);
  const [userIdError, setUserIdError] = useState(true);
  const [name, setName]= useState(location.state.profile.properties.nickname);
  const [userId, setUserId] = useState("");
  const onChangeId = (e) => {
    
    var userIdRegex = /^[0-9]{4,4}$/;
    if(e.target.value =='') setNameError(true);
    
    else if ((!e.target.value || (userIdRegex.test(e.target.value)))) setUserIdError(false);
    
    else setUserIdError(true);
    setUserId(e.target.value);
};


const onChangeName = (e) => {
    
    var userIdRegex = /^[A-z가-힣 -]{2,15}$/;
    if(e.target.value =='') setNameError(true);
    
    else if ((!e.target.value || (userIdRegex.test(e.target.value)))) setNameError(false);
    
    else{
        setNameError(true);
        
    } 
    setName(e.target.value);
    
    
};


  const onReset = () => {
    
    setUserId('');
    setName('');
    setUserIdError(true);
    setNameError(true);
    
  };
  
const Submit = ()=>{
    if(nameError) alert("이름 정보를 확인해 주세요.");
    else if(userIdError) alert("학번 정보를 확인해 주세요.");
    navigate("/setPos", {state: {
        name: name,
        id: userId,
        kakaoId: location.state.profile.id,
        imgUrl: location.state.profile.properties.thumbnail_image
    }});
    
   
};


    return(
    <div className="h-full flex flex-col border-yellow rounded-3xl border-2 bg-orange-50 p-4 items-center text-left text-2xl indent-2 gap-5"> 
        <div className = "flex flex-row gap-3 items-center w-15"><p className= "w-1/4 p-2">성명: </p>
        <input className = "w-3/4 border-black border-2" id = "name" name="name" placeholder="(예시: 홍길동)" onChange = {onChangeName} value ={name}/>
        
        </div>
        {nameError && <div className = 'h-5 text-base font-bold text-red-500'>이름은 2글자 이상이어야 합니다.</div>}
        {!nameError && <div className = 'h-5'> </div>}
        <div className = "flex flex-row gap-3 items-center">
            <p className= "w-1/4  p-2">학번: </p>
        <input className = "w-3/4 border-black border-2" id = "userId" name="userId" placeholder="(예시:2006)" onChange={onChangeId} value ={userId} /> 
        
        </div>
        {userIdError && <div className = 'h-5 text-base font-bold text-red-500'>학번은 4자리의 숫자를 입력 하셔야 합니다.</div>}
        {!userIdError && <div className = 'h-5'> </div>}
        
        <div className = "flex w-full flex-row gap-5 justify-center items-center py-10">
        <button className = "border-balck rounded-2xl border-black border-2 bg-gray-100 p-6" onClick={onReset}><b>초기화</b></button>
        <button className = "border-balck rounded-2xl border-black border-2 bg-gray-100 p-6" onClick = {Submit}><b>저 장</b></button>
            
        </div>
        
        </div>
    );
}
export default InputSample;