import React from 'react';
import { Link, Outlet, BrowserRouter, Routes, Route } from "react-router-dom";
import SocialKakao from './kakaoLoginButton';

import ReactDOM from 'react-dom/client';

export default function frontMain(){
    const newLocal = <div></div>;
    return(
        <div className='grow flex flex-col gap-5'>
        <div className="h-full flex flex-col border-yellow rounded-3xl border-2 bg-orange-50 p-4 text-left text-2xl indent-2"> 
        
        <p className = "w-full"> 고려대학교 하날다래 동아리 회원들의 현재 직장 및 거주지 정보를 수집 및 공유하기 위한 사이트입니다. </p>
        <p className = "w-full"> 무분별한 정보 유출 방지를 위해 로그인 후 직장 또는 거주지 정보를 한 가지 이상 공유하여야 정보 열람이 가능합니다. </p>
        <p className = "w-full"> 하단의 로그인 버튼을 통해 시작해 주세요.</p>
        <div className = "flex flex-col text-right grow text-lg"><div className = "grow"></div><p>만든이: 공육 사남 김 기 훈</p><p>(penorchid)</p></div>        
        </div>
        <div className = "w-full flex flex-col items-center">
        
            <SocialKakao/>
        </div>
        
        </div>
        
        
        
    );
};