import React from 'react';
import { Link, Outlet, BrowserRouter, Routes, Route } from "react-router-dom";

import ReactDOM from 'react-dom/client';
import './tailwind.css';
import Front from './frontMain'
import Map from './selectPos';
import UserInput from './userData';
import reportWebVitals from './reportWebVitals';
import Explore from './explore'
const root = ReactDOM.createRoot(document.getElementById('root'));
const test = false;
root.render(
  
  <React.StrictMode>

    <main className="flex min-h-screen max-w-screen flex-col items-center items-stretch justify-top gap-5 bg-black p-5">
      

      <div className="border-yellow rounded-3xl border-2 bg-blue-300 p-4 text-center text-2xl">
        <p className= "text-4xl" >하 날 다 래</p>
        <p>어  딧  슈 v0.1</p>
      </div>
      
      
    

     
    

    
    <BrowserRouter>
      <Routes>
        <Route path = "/" element ={<Front/> }></Route>
        <Route path ="/setPos" element={<Map />}></Route>
        <Route path = "/explorer" element ={<Explore/>}></Route>
        <Route path = "/userInput" element = {<UserInput/>}></Route>
        
        <Route path = "*" element="NotFound Page"></Route>
      </Routes>      
      
    </BrowserRouter>   
    
    
    </main>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
