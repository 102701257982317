import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from "react";
import { useState, useEffect } from 'react';
import SocialKakao from './kakaoLoginButton'
import './tailwind.css';
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps';
import {GeoJsonLayer} from '@deck.gl/layers';
import { useLocation, useNavigate } from "react-router";
let map: google.maps.Map,
marker: google.maps.marker,
infoWindow: google.maps.InfoWindow;

    
const containerStyle = {
  width: '400px',
  height: '400px'
};
var temp = "https://k.kakaocdn.net/dn/tAJzC/btsnC7pA7Bw/DYkhcweYVCA8LrLB9vPvC0/img_110x110.jpg";


var userPos = {
  lat: 37.582465,
  lng: 127.027507,
};
function DrawMap() {
  const location = useLocation();
  const navigate = useNavigate();
  if(!location.state) navigate('/');
  //console.log(location.state);
    
  const { isLoaded } = useJsApiLoader({
    id: "7883070c8104cf75",
    version: "beta",
    googleMapsApiKey: "AIzaSyDW5YfBioq24mmoqNqYCnneH42D-CBzEkc",
    
  });
  
  const [map, setMap] = React.useState(null);
  const glyphImg = document.createElement('img');
  glyphImg.src = temp;
  //
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    var bounds = new window.google.maps.LatLngBounds(userPos);
    map.addListener('mapcapabilities_changed', () => {
      const mapCapabilities = map.getMapCapabilities();
    
      if (!mapCapabilities.isAdvancedMarkersAvailable) {
        //console.log("187");
      }
    });
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          userPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          
        },
        () => {
          console.log("Nodata");
        }
        
      );
    } else {
      console.log("Nodata");
    }
    var infowindow = new google.maps.InfoWindow();

          var marker = new google.maps.Marker ( {
            position: userPos,
            map,
            
            /*{
              url: temp,
              scaledSize: new google.maps.Size(72, 72),
              anchor: new google.maps.Point(36, 36),
              strokeOpacity: 1
            },*/
            
            
            draggable: true,
            mapId: "7883070c8104cf75"
          });
          
          const imgUrl = location.state.imgUrl?location.state.imgUrl:'/defaultIcon.png';
          const contentStr = '<div class="flex flex-row w-full gap-4"><img class = "flex h-16 w-16 rounded-3xl" src = '+imgUrl +'>'+'<div class = "flex flex-col text-lg font-bold"><div>학번:'+location.state.id+'</div><div>이름:'+location.state.name+'</div></div></div>';
          marker.addListener("click",()=>{
            map.panTo(marker.position);
            infowindow.setContent(contentStr);
            infowindow.open({
              anchor:marker,
              map,
            })
            
          });
          marker.addListener("dragend",()=>{
            userPos = {
              lat: marker.getPosition().lat(),
              lng: marker.getPosition().lng(),
            };
            console.log(userPos);
          });
          map.setCenter(marker.position);
          
          bounds = new window.google.maps.LatLngBounds(userPos);
    //map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function savePosition(){
    var apiUri = 'https://'+window.location.hostname+':3005/saveData';
    console.log(userPos);
    fetch(apiUri,{
      method: "POST",
      headers:{
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: location.state.name,
        id: location.state.id,
        kakaoId: location.state.kakaoId,
        imgUrl: location.state.imgUrl,
        pos: userPos        
      })
    }).then(response=>{
      console.log(response);
      navigate("/explorer", {state: true});
    })

    
    
    
  }
  
  return isLoaded ? (
    <div className="flex flex-col grow">
      <GoogleMap
      mapContainerClassName={
        "grow border-2 border-yellow overflow-hidden bg-blue rounded-3xl"
      }
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ mapId: "7883070c8104cf75" }}
      >
     
    </GoogleMap>
    
    <button className = "border-balck rounded-2xl border-black border-2 bg-yellow-300 p-6" onClick={savePosition}><b>여 깃 슈</b></button>
    
    </div>
    
    
  ) : (
    <>test</>
  );
}


export default DrawMap;
