import KakaoLogin from "react-kakao-login";
import { useNavigate} from "react-router-dom";
import { useHistory } from 'react-router-dom';

var isLogin = false;


const SocialKakao =()=>{
    
    const kakaoClientId = '9525418d6b0fca4f2395656d46c71ced'
    const navigate = useNavigate();
    
    const kakaoOnSuccess = async (data)=>{
      	
        isLogin = true;
        
        const idToken = data.response.access_token  // 엑세스 토큰 백엔드로 전달
    
        sessionStorage.setItem('isLogin', idToken);
        var apiUri = 'https://'+window.location.hostname+':3005/isExist';
        console.log(apiUri);
        fetch(apiUri,{
            method: "GET",
            headers: {
                //'Content-type': 'application/json',
                'kakaoId': data.profile.id
            }
        }) .then(response => response.json())
        .then(response => {
          if(response.isExist){
            navigate("/explorer", {state: true});

          }else{
            navigate("/userInput", {state: data});

          }
        });
        
        
        console.log(data);
        

    }
    const kakaoOnFailure = (error) => {
        console.log(error);
    };

    return true?( 
        
          <KakaoLogin
              token={kakaoClientId}
              onSuccess={kakaoOnSuccess}
              onFail={kakaoOnFailure}
          />
        
    ):(<></>);
}

export default SocialKakao

