import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from "react";
import { useState, useEffect } from 'react';
import SocialKakao from './kakaoLoginButton'
import './tailwind.css';
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps';
import {GeoJsonLayer} from '@deck.gl/layers';
import { useLocation, useNavigate } from "react-router";
import {MarkerClusterer } from"@googlemaps/markerclusterer";

let map: google.maps.Map,
marker: google.maps.marker,
infoWindow: google.maps.InfoWindow;

    
const containerStyle = {
  width: '400px',
  height: '400px'
};
var temp = "https://k.kakaocdn.net/dn/tAJzC/btsnC7pA7Bw/DYkhcweYVCA8LrLB9vPvC0/img_110x110.jpg";


var userPos = {
  lat: 37.582465,
  lng: 127.027507,
};

export default function DrawMapp() {
  
  const location = useLocation();
  const navigate = useNavigate();
  if(!location.state) navigate('/');
  
  //console.log(location.state);
    
  const { isLoaded } = useJsApiLoader({
    id: "7883070c8104cf75",
    version: "beta",
    googleMapsApiKey: "AIzaSyDW5YfBioq24mmoqNqYCnneH42D-CBzEkc",
    
  });
  
  const [map, setMap] = React.useState(null);
  
  //
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    var bounds = new window.google.maps.LatLngBounds(userPos);
    map.addListener('mapcapabilities_changed', () => {
      const mapCapabilities = map.getMapCapabilities();
    
      if (!mapCapabilities.isAdvancedMarkersAvailable) {
        //console.log("187");
      }
    });
    function getRandom(min, max){
      return Math.random() * (max - min) + min;
    }
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          userPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          
          

          
          /*
          var marker = new google.maps.Marker ( {
            position: userPos,
            map,
            
            
            
            
            mapId: "7883070c8104cf75"
          });
          
          
          const contentStr = '';
          marker.addListener("click",()=>{
            map.panTo(marker.position);
            infowindow.setContent(contentStr);
            infowindow.open({
              anchor:marker,
              map,
            })
            
          });
          */
          
          
        },
        () => {
          console.log("Nodata");
        }
        
      );
    } else {
      console.log("Nodata");
    }
    var infowindow = new google.maps.InfoWindow();
          map.setCenter(userPos);
          var apiUri = 'https://'+window.location.hostname+':3005/getMarkers';
          let markers =[];
          const changeProtocol = (url) => {
            if (!url) {
                return '';
            }
            url = url.replace(/(^\w+:|^)\/\//, '');
            return 'https://'+url;
        }
          fetch(apiUri).then(response=>response.json())
          .then(response=>{
            for(var i =0; i< response.length; i++){
            //markers = response.map((data, i)=>{
                
              
              var data = response[i];
              data.pos.lat += getRandom(-0.001, 0.001);
              data.pos.lng += getRandom(-0.001, 0.001);
              const marker = new google.maps.Marker ( {
                position: data.pos,
                map,
               
                mapId: "7883070c8104cf75"
              });
              
              markers.push(marker);
              const imgUrl = data.imgUrl?changeProtocol(data.imgUrl):'/defaultIcon.png';
              
              const contentStr = '<div class="flex flex-row w-full gap-4"><img class = "flex h-16 w-16 rounded-3xl" src = '+imgUrl +'>'+'<div class = "flex flex-col text-lg font-bold"><div>학번:'+data.id+'</div><div>이름:'+data.name+'</div></div></div>';
              marker.addListener("click",()=>{
                infowindow.setContent(contentStr);
                infowindow.open(map, marker);
                map.panTo(marker.getPosition());
                //console.log(imgUrl);
                
              });
            }
          
            
          const markerCluster = new MarkerClusterer({map, markers});
            //
          }
          );
          bounds = new window.google.maps.LatLngBounds(userPos);
    //map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  
  
  return isLoaded ? (
    <div className="flex flex-col grow">
      <GoogleMap
      mapContainerClassName={
        "grow border-2 border-yellow overflow-hidden bg-blue rounded-3xl"
      }
      zoom={10}
      
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ mapId: "7883070c8104cf75", disableDefaultUI: true, maxZoom: 18, minZoom: 3, keyboardShortcuts: false }}
      >
     
    </GoogleMap>
    
    
    
    </div>
    
    
  ) : (
    <>test</>
  );
}


 
